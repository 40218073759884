import '../css/FormattedImage.scss';

function FormattedImage({
  imageFile,
  largeImage,
  handleImageClick,
}: {
  imageFile: string;
  largeImage: string;
  handleImageClick: Function;
}) {
  return (
    <div
      className="formatted-image-container"
      onClick={() => handleImageClick(largeImage)}
    >
      <img className="formatted-image" src={imageFile} alt="" />
    </div>
  );
}

export default FormattedImage;
