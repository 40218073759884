import { useState } from 'react';
import '../css/GalleryView.scss';
import EnlargedImage from '../components/EnlargedImage';
import MonthlyImageContainer from '../components/MonthlyImageContainer';

function GalleryView() {
  const [selectedImage, setSelectedImage] = useState<string>();
  const [showEnlargedImage, setShowEnlargedImage] = useState(false);

  const handleImageClick = (imageFile: string) => {
    setSelectedImage(imageFile);
    setShowEnlargedImage(true);
  };

  return (
    <div className="gallery-view-container">
      <MonthlyImageContainer handleImageClick={handleImageClick} />
      <EnlargedImage
        imageFile={selectedImage}
        setShowEnlargedImage={setShowEnlargedImage}
        showEnlargedImage={showEnlargedImage}
      />
    </div>
  );
}

export default GalleryView;
