import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const StyledOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const shake = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-1px); }
  50% { transform: translateX(1px); }
  75% { transform: translateX(-1px); }
  100% { transform: translateX(0); }
`;

const StyledOption = styled.div`
  border: 1px solid black;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 5%;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    animation: ${shake} 0.5s ease;
  }
`;

const HomePage = () => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
  };

  return (
    <StyledContainer>
      <StyledOptionsContainer>
        <StyledOption onClick={() => handleClick('/alexandra')}>
          Alexandra
        </StyledOption>
      </StyledOptionsContainer>
    </StyledContainer>
  );
};

export default HomePage;
