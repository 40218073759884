import '../css/EnlargedImage.scss';

function EnlargedImage({
  imageFile,
  showEnlargedImage,
  setShowEnlargedImage,
}: {
  imageFile: string | undefined;
  showEnlargedImage: boolean;
  setShowEnlargedImage: Function;
}) {
  if (!showEnlargedImage || !imageFile) return null;

  return (
    <div
      className="enlarged-image-container"
      onClick={() => setShowEnlargedImage(false)}
    >
      <img className="enlarged-image" src={imageFile} alt="" />
    </div>
  );
}

export default EnlargedImage;
