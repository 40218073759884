const IMAGES = [
  {
    year: 2023,
    month: 'January',
    caption: `Spent a week at St. Elizabeth's after the birth. Time spent at home. Small trips around Boston.`,
    images: [
      {
        large: require('./images/20230102_01.jpeg'),
        small: require('./images/20230102_01_small.jpeg'),
        caption: 'Svet and Daren one week before due date.',
        date: new Date(2023, 0, 2),
      },
      {
        large: require('./images/20230102_02.jpeg'),
        small: require('./images/20230102_02_small.jpeg'),
        caption: 'Svet one week before due date.',
        date: new Date(2023, 0, 2),
      },
      {
        large: require('./images/20230109_01.jpeg'),
        small: require('./images/20230109_01_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230109_02.jpeg'),
        small: require('./images/20230109_02_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230109_03.jpeg'),
        small: require('./images/20230109_03_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230109_04.jpeg'),
        small: require('./images/20230109_04_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230109_05.jpeg'),
        small: require('./images/20230109_05_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230109_06.jpeg'),
        small: require('./images/20230109_06_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230109_07.jpeg'),
        small: require('./images/20230109_07_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230109_08.jpeg'),
        small: require('./images/20230109_08_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230109_09.jpeg'),
        small: require('./images/20230109_09_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230109_10.jpeg'),
        small: require('./images/20230109_10_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230109_11.jpeg'),
        small: require('./images/20230109_11_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230110_01.jpeg'),
        small: require('./images/20230110_01_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230110_02.jpeg'),
        small: require('./images/20230110_02_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230111_01.jpeg'),
        small: require('./images/20230111_01_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230111_02.jpeg'),
        small: require('./images/20230111_02_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230111_03.jpeg'),
        small: require('./images/20230111_03_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230111_04.jpeg'),
        small: require('./images/20230111_04_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230112_01.jpeg'),
        small: require('./images/20230112_01_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230113_01.jpeg'),
        small: require('./images/20230113_01_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230113_02.jpeg'),
        small: require('./images/20230113_02_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230113_03.jpeg'),
        small: require('./images/20230113_03_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230113_04.jpeg'),
        small: require('./images/20230113_04_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230113_05.jpeg'),
        small: require('./images/20230113_05_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230113_06.jpeg'),
        small: require('./images/20230113_06_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230116_01.jpeg'),
        small: require('./images/20230116_01_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230116_02.jpeg'),
        small: require('./images/20230116_02_small.jpeg'),
        caption: null,
      },
      {
        large: require('./images/20230123_01.jpeg'),
        small: require('./images/20230123_01_small.jpeg'),
        caption: null,
      },
    ],
  },
];

export default IMAGES;
