import IMAGES from '../assets';
import '../css/GalleryView.scss';
import FormattedImage from './FormattedImage';

function MonthlyImageContainer({
  handleImageClick,
}: {
  handleImageClick: Function;
}) {
  return (
    <>
      {IMAGES.map((monthlyImageData) => (
        <div className="month-container">
          <h1 style={{ color: 'white' }}>
            {monthlyImageData.month}, {monthlyImageData.year}
          </h1>
          <p style={{ color: 'white' }}>{monthlyImageData.caption}</p>
          <div className="month-images-container">
            {monthlyImageData.images.map((imageData) => (
              <FormattedImage
                key={imageData.large}
                imageFile={imageData.small}
                largeImage={imageData.large}
                handleImageClick={handleImageClick}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

export default MonthlyImageContainer;
